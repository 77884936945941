import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { Col, Form, Row, Switch } from 'antd';
import React from 'react';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { uploadImageNormalize } from '../../../../../../../../common/utils';
import DraggerUploadComponent from '../../../../../../../../components/DraggerUploadComponent';
import InputComponent from '../../../../../../../../components/InputComponent';

const DragHandle = sortableHandle(() => <MenuOutlined className="drag-row" />);

const SortableItem = sortableElement(({ value = null, otherConfig = null }) => {
  const { key = null, name = null, ...restField } = value;

  return (
    <div className="dragging-class">
      <div className="d-flex justify-between align-center">
        <span className="switch-logo font-500">
          <DragHandle /> &nbsp;
          {otherConfig?.[key]?.blockTitle}
        </span>

        <Form.Item
          {...restField}
          name={[name, 'blockCheck']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      {otherConfig?.[key]?.blockCheck && (
        <Row align="middle" justify="space-between" key={key} gutter={4}>
          <Col span={8}>
            <Form.Item
              {...restField}
              name={[name, 'image']}
              className="mb-0"
              normalize={uploadImageNormalize}
              valuePropName="fileList"
            >
              <DraggerUploadComponent className="common-upload">
                {' '}
                <p className="icon-header">
                  <DownloadOutlined />
                </p>
                <span className="upload-title">
                  Drag image or browse your files
                </span>{' '}
                <br />
                <span className="upload-description">JPG, PNG, SVG, JPEG</span>
              </DraggerUploadComponent>
            </Form.Item>
          </Col>
          <Col className="ml-8" span={14}>
            <Form.Item
              {...restField}
              label="Label"
              name={[name, 'label']}
              className="mb-0"
              rules={[
                {
                  required: otherConfig?.[key]?.blockCheck,
                  message: 'Please Enter Label'
                }
              ]}
            >
              <InputComponent placeholder="Enter Label" />
            </Form.Item>
            <Form.Item
              {...restField}
              label="Description"
              name={[name, 'blockDescription']}
              className="mb-0"
            >
              <InputComponent placeholder="Enter Description" />
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
});

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const SystemPickListForm = ({
  form,
  setDisableBtn,
  systemFieldData,
  setSystemFieldData
}) => {
  const otherConfig = Form?.useWatch(
    ['widgetConfiguration', 'otherConfig'],
    form
  );

  const onSortEnd = ({ newIndex, oldIndex }) => {
    if (oldIndex !== newIndex) {
      const optionsCopy = [...otherConfig];
      optionsCopy?.splice(newIndex, 0, optionsCopy?.splice(oldIndex, 1)?.[0]);
      form?.setFieldValue(['widgetConfiguration', 'otherConfig'], optionsCopy);
      setSystemFieldData({
        ...systemFieldData,
        config: {
          ...systemFieldData?.config,
          otherConfig: [...optionsCopy]
        }
      });
      setDisableBtn(false);
    }
  };

  return (
    <Form.List name={['widgetConfiguration', 'otherConfig']}>
      {(fields) => (
        <>
          <SortableContainer
            useDragHandle
            onSortEnd={onSortEnd}
            helperClass="pick-list-draggable-option"
          >
            {fields?.map((field, index) => {
              const { key = null } = field;
              return (
                <SortableItem
                  key={key}
                  value={field}
                  index={index}
                  otherConfig={otherConfig}
                />
              );
            })}
          </SortableContainer>
        </>
      )}
    </Form.List>
  );
};
export default SystemPickListForm;
